@import 'config'; /* environment config */

@import '../colors';
@import '../variables';

.sign-up {
  &__container {
    padding-bottom: 100px;
  }

  & .custom-radio {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;

    input[type="radio"] {
      position: relative;
      margin-top: 10px;

      &::before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $color-white;
        border-radius: 100%;
        border: 2px solid $color-border-box-price-plans;
      }
    }

    input[type="radio"]:checked {
      &::after {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $color-border-box-price-plans;
        border-radius: 100%;
      }
    }

    & label {
      padding-left: 10px;
    }
  }

  &__title {
    padding-bottom: 40px;
  }

  &__label {
    font-size: 22px;
    color: $color-text-body;
    padding-bottom: 10px;
  }

  &__text {
    font-size: 16px;
    color: $color-text-body;
  }

  &__button {
    margin-top: 30px;

    & button {
      font-size: 18px;
    }
  }
}
